import React from 'react';
import {Trans, useTranslation} from "gatsby-plugin-react-i18next";
import CustomLocalizedLink from "../locales/custom-localized-link";

const FaqSection = ({product}) => {
  const {t} = useTranslation();
  return (
      <div className="container">
        <h1>
          <Trans>faq</Trans>
        </h1>
        <span dangerouslySetInnerHTML={{__html: t('detailed_support_use')+', '}} />
        <CustomLocalizedLink goto={'/support/#gotoFaq'} label={t('visit_support_page')} />
        <span>.</span>
      </div>
  )
}
export default FaqSection;